@import "../assets/fonts.scss";
@import "../assets/colors.scss";

#container {
  padding: 15px;
  background: $navbar-background-color;
  display: flex;
  justify-content: space-between;
  height: 60px;
}
#title {
  font-family: "Raleway";
  font-weight: bolder;
  font-size: $md-size;
  line-height: 30px;
  color: $navbar-text-color;
}
