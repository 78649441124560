@import "./assets/fonts.scss";
@import "./assets/colors.scss";

#container {
  padding: 20px;
}
#orderDetailsConatiner {
  padding: 20px 0px 10px 0px;
  border-left: 1px solid lightgrey;
  height: 94vh;
}
#orderDetailsTitle {
  color: $order-details-heading-color;
  font-size: $sm-size;
}
